import React, {Fragment, useContext } from 'react'
import {CheckoutContext} from '../../context/CheckoutContext'

import { ThemeProvider } from 'styled-components';
import { saasTheme } from '../../common/src/theme/saas';
import { ResetCSS } from '../../common/src/assets/css/style';
import { GlobalStyle } from '../containers/Saas/saas.style';
import PropTypes from 'prop-types';
import {Link} from 'gatsby'
import Box from '../../common/src/components/Box';
import Text from '../../common/src/components/Text';
import Heading from '../../common/src/components/Heading';
import Button from '../../common/src/components/Button';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import Icon from '@mdi/react'
import { mdiWhatsapp } from '@mdi/js';



import PricingTable from '../containers/Saas/PricingSection/pricing.style.js';

import {
  TimelineWrapper,
  TimelineItem,
  TimelineIndex,
  TimelineContent,
  TimelineDot,
  Hidden,
} from '../containers/Saas/TimelineSection/timeline.style';

const Success = ({
  title,
  description,
  timelineDescription,
  timelineTitle,
  textArea,
  indexStyle,
  buttonStyle,
  buttonFillStyle
}) => {  

  const data = useContext(CheckoutContext)
  console.log(data)

  return (
    <>
       <ThemeProvider theme={saasTheme}>
      <Fragment>

        <SEO title="Molinas Nutricion" />
        <ResetCSS />
        <GlobalStyle />
        <div style={{display:"flex", flexDirection:"row", alignItems:'center', justifyContent:"center"}}>
        <PricingTable className="pricing_table" style={{width:'90vw',  margin:'5% 0'}}>
          <Box >
          <Text {...title}  content="¡GRACIAS POR ELEGIRNOS!" />
          <Heading
          {...description}
            content="Sigue los siguientes pasos para agendar tu cita."
          />
        </Box>
        <Box  style={{display:"flex", flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
  
        <TimelineWrapper>

                <TimelineItem key={`timeline-item-01`}>
                  <TimelineIndex>
                    <Hidden>
                      <Slide bottom>
                        <Text
                          as="span"
                          content={ `02`}
                          {...indexStyle}
                        />
                      </Slide>
                    </Hidden>
                  </TimelineIndex>
                  <TimelineContent>
                    <Hidden>
                      <Slide bottom delay={100}>
                        <Heading
                          as="h2"
                          content="Ponte en contacto"
                          {...timelineTitle}
                        />
                      </Slide>
                    </Hidden>
                    <Hidden>
                      <Slide bottom delay={200}>
                        <Text
                          content={`Haz click en el icono de Whatsapp para agendar tu cita.`}
                          {...timelineDescription}
                        />
                      </Slide>
                    </Hidden>
                    <Hidden style={{paddingTop:"2%"}}>
                      <Slide bottom delay={200} >
                      <a  href="https://wa.me/529992796845?text=Hola,%20acabo%20de%20hacer%20mi%20pago%20y%20quisiera%20agendar%20mi%20cita." target="_blank" rel="noopener noreferrer">
                      <Icon color="#c73279" path={mdiWhatsapp} size="3em"/>
                      </a>
                      
                      </Slide>
                    </Hidden>
                  </TimelineContent>
                  <TimelineDot />
                </TimelineItem>

                <TimelineItem key={`timeline-item-02`}>
                  <TimelineIndex>
                    <Hidden>
                      <Slide bottom>
                        <Text
                          as="span"
                          content={ `03`}
                          {...indexStyle}
                        />
                      </Slide>
                    </Hidden>
                  </TimelineIndex>
                  <TimelineContent>
                    <Hidden>
                      <Slide bottom delay={100}>
                        <Heading
                          as="h2"
                          content="Vuelve a la página"
                          {...timelineTitle}
                        />
                      </Slide>
                    </Hidden>
                    <Hidden>
                      <Slide bottom delay={200}>
                        <Text
                          content="Si te quedaste con dudas, regresa a la página principal."
                          {...timelineDescription}
                        />
                      </Slide>
                    </Hidden>
                    <Hidden style={{paddingTop:"3%"}}>
                      <Slide bottom delay={200}>
                      <Link to="/">
                        <Button 
                        
                        style={{width:"85%"}}
                          title='pagina principal'
                            {...buttonStyle}
                        />
                      </Link>
                       
                      </Slide>
                    </Hidden>
                  </TimelineContent>
                  <TimelineDot />
                </TimelineItem>
         
            </TimelineWrapper>

          </Box>
        </PricingTable>
        </div>
      </Fragment>
    </ThemeProvider>
    </>
    
  )
}

Success.propTypes = {

  title: PropTypes.object,
  description: PropTypes.object,
  timelineDescription: PropTypes.object,
  timelineTitle: PropTypes.object,
  textArea: PropTypes.object,
  indexStyle: PropTypes.object,
  buttonStyle:PropTypes.object,
  buttonFIllStyle: PropTypes.object,

};

Success.defaultProps = {

  title: {
    fontSize: ['32px', '32px', '36px', '42px', '48px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '28px',
    textAlign: 'center',
    lineHeight: '1.25',
  },
  description: {
    fontSize: ['15px', '15px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '2.1',
    textAlign: 'center',
    mb: ['35px', '35px', '40px', '60px'],
  },
  timelineDescription: {
    fontSize: ['14px', '15px', '15px', '15px', '16px'],
    lineHeight: '2',
    color: '#5d646d',
    mb: '0',
  },
  timelineTitle: {
    fontSize: ['16px', '17px', '18px', '18px', '20px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '13px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '55%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  indexStyle: {
    fontSize: ['36px', '42px', '52px', '56px', '72px'],
    fontWeight: '300',
    color: '#eaebec',
    display: 'block',
    lineHeight: '1',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primary',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
};

export default Success
